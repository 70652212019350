import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PRODUCT_WALKTHROUGH_TYPE } from '../../contract-management/components/contract-list/contract-list.component';

@Injectable({
    providedIn: 'root'
})
export class ProductWalkThroughService {
    private walkThroughReset = new Subject<PRODUCT_WALKTHROUGH_TYPE>();
    walkThroughReset$ = this.walkThroughReset.asObservable();

    resetWalkThrough(type: PRODUCT_WALKTHROUGH_TYPE) {
        this.walkThroughReset.next(type);
    }
}
