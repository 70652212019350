import { Injectable } from "@angular/core";
import { BaseService } from "../../@core/services/base.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment.dev";
import { IUser } from "../../@core/models/model";
import { IChecklistItem, IChecklistItemRule, IChecklistItemTag, ITag, ITagType, ITypeOfContract } from "../models/settings.model";

@Injectable({ providedIn: 'root' })
export class ChecklistsService extends BaseService {

    constructor(
        protected override http: HttpClient
    ) {
        super(http);
    }


    apiUrl = environment.apiUrl + '/contract-checklists'

    getChecklists(filters: { skip: number, limit: number, documentType: string, fetchTags?: boolean, fetchRisks?: boolean }) {
        return this.post<{ checklists: Partial<IChecklistItem>[], tags?: any, risks?: any, total?: number }>(this.apiUrl, filters)
    }

    createChecklist(data: any) {
        return this.post<any>(this.apiUrl + '/create', data)
    }

    updateChecklistById(data: {}) {
        return this.put<any>(this.apiUrl, data)
    }

    deleteChecklistById(id: string) {
        return this.delete<IChecklistItem>(this.apiUrl + `/${id}`)
    }

    getClients() {
        return this.get<{ _id: string, linkedUser: Partial<IUser>, companyName: string }[]>(this.apiUrl + '/clients', {})
    }

    getKeyPoints() {
        return this.get<Partial<ITagType>[]>(this.apiUrl + '/key-points', {})
    }

    getTypeOfContracts() {
        return this.get<Partial<ITypeOfContract>[]>(this.apiUrl + '/type-of-contracts', {})
    }

    getLocation() {
        return this.get<any>(this.apiUrl + '/locations', {});
    }

    linkTagToChecklist(checklistId: string, data: { tag: string, checklist: string, tagType: string }[], allTags: boolean) {
        return this.post<(IChecklistItemTag & { tagType: ITagType, value: ITag })[]>(this.apiUrl + '/link-tag', { checklistId, data, allTags })
    }

    deleteTagLinkedToChecklist(checklistId: string, tagIds: string[], deleteMappings: boolean) {
        return this.delete<(IChecklistItemTag & { tagType: ITagType, value: ITag })[]>(this.apiUrl + '/tag', { checklistId, tagIds, deleteMappings })
    }

    getRulesLinkedToChecklist(checklist: string) {
        return this.get<IChecklistItemRule>(this.apiUrl + '/rules/' + checklist);
    }

    addRulesToChecklist(data: any) {
        return this.post<any>(this.apiUrl + '/add-rules', data)
    }

    deleteRulesLinkedToChecklist(tagId: string) {
        return this.delete<any>(this.apiUrl + '/rules/' + tagId)
    }

    copyChecklist(checklistId: string) {
        return this.post(this.apiUrl + '/copy', { checklistId })
    }

    downloadSampleExcel() {
        return this.get<{ data: Buffer }>(this.apiUrl + '/sample-excel')
    }

    downloadExistingExcel() {
        return this.get<{ data: Buffer }>(this.apiUrl + '/download-excel')
    }

    uploadExcel(data: any) {
        return this.post<{ buffer?: { data: Buffer } }>(this.apiUrl + '/upload-excel', data)
    }
}