import { Injectable } from '@angular/core';
import { BaseService } from '../@core/services/base.service';
import { PhoneNumberValue } from '../@shared/components/phone-input/phone-input.component';

@Injectable({
    providedIn: 'root'
})
export class GeolocationService extends BaseService {
    public country: PhoneNumberValue = { countryCode: '1', contactNumber: '', countryShortName: 'US' };

    getCountry(): PhoneNumberValue {
        return this.country;
    }

    fetchCountryCodes = async () => {
        try {
            const response = await fetch(`https://ipapi.co/json/`);
            const data = await response.json();
            const countryCodes: PhoneNumberValue = { countryCode: data.country_calling_code.replace('+', ''), contactNumber: '', countryShortName: data.country_code };
            this.country = countryCodes;
            return countryCodes;
        }
        catch (err) {
            console.log(err)
            return this.country
        }
    }
}