import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxChangeEvent, CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { SkeletonModule } from 'primeng/skeleton';
import { IContract } from '../../../contract-management/models/contract-management.model';
import { UserAvatarPipe } from '../../pipes/user-avatar-pipe';
import { FullNamePipe } from '../../pipes/fullName.pipe';
import { IInfiniteScrollEvent, InfiniteScrollDirective } from "ngx-infinite-scroll"
import { MarkdownModule } from "ngx-markdown";
import _ from "lodash";
import { TooltipModule } from 'primeng/tooltip';
import { ThrottleClickDirective } from '../../directives/throttle-click.directive';

export interface SelectionDialogBase {
  _id?: string;
  name: string;
  selected?: boolean;
}

@Component({
  selector: 'app-selection-dialog',
  standalone: true,
  imports: [DialogModule, InputGroupModule, CheckboxModule, InputGroupAddonModule, ButtonModule, FormsModule, SkeletonModule, CommonModule, UserAvatarPipe, FullNamePipe, InfiniteScrollDirective, TooltipModule, MarkdownModule, ThrottleClickDirective],
  templateUrl: './selection-dialog.component.html',
  styleUrl: './selection-dialog.component.scss'
})
export class SelectionDialogComponent<T extends SelectionDialogBase> implements OnInit {

  @ViewChild('leftList') leftListRef!: ElementRef;
  @Input() visible: boolean = false
  @Input() title: string = ''
  @Input() selectionType: string = ''
  @Input() label: string = ''
  @Input() saveLoader: boolean = false
  @Input() isLoading: boolean = false
  @Input() infiniteScroll: boolean = false
  @Input() isItemsLoading: boolean = false

  //data input
  private _data: T[] = [];
  @Input()
  set data(value: T[]) {
    this._data = value;
    this.syncDataWithAllSelectedItems();
    this.filteredData = [...this._data];
    this.updateSelectedItems();
  }
  get data() {
    return this._data;
  }

  private _newData?: T[] = [];
  @Input()
  set newData(value: T[]) {
    this._newData = value;

    if (this._newData && this._newData.length > 0) {
      this._newData.forEach(newItem => {
        const existingSelected = this.allSelectedItems.find(item => item._id === newItem._id);
        if (existingSelected) {
          newItem.selected = true;
        }
      });
    }

    this.filteredData.push(...this._newData);
    this._data = [...this.filteredData];
    this.updateSelectedItems();

    setTimeout(() => {
      this.scrollToBottom();
    }, 0);
  }
  get newData() {
    return this._newData || [];
  }

  @Output() onSelectionSubmit = new EventEmitter<T[]>();
  @Output() onDialogClose = new EventEmitter<void>();
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onScrollEndReached = new EventEmitter<string>();
  @Output() onSearchItem = new EventEmitter<string>();

  searchValue: string = ''
  selectAll: boolean = false
  previousScrollTop: number = 0

  filteredData: T[] = []
  selectedItems: T[] = []
  allSelectedItems: T[] = []

  constructor() {
  }

  ngOnInit() {
  }

  syncDataWithAllSelectedItems() {
    if (this.allSelectedItems.length > 0) {
      this._data.forEach(dataItem => {
        const existingSelected = this.allSelectedItems.find(item => item._id === dataItem._id);
        if (existingSelected) {
          dataItem.selected = true;
        }
      });
    }
  }

  onFilter() {
    if (this.infiniteScroll) {
      this.isItemsLoading = true;
      this.searchItems();
    } else {
      this.filterItems();
    }
  }

  filterItems() {
    this.filteredData = (this.data).filter(item => (item.name.toLowerCase()).includes(this.searchValue.toLowerCase()));

    this.filteredData.forEach(item => {
      const existingSelected = this.allSelectedItems.find(selectedItem => selectedItem._id === item._id);
      if (existingSelected) {
        item.selected = true;
      }
    });

    this.updateSelectAllState();
  }

  searchItems = _.debounce(() => {
    this.onSearchItem.emit(this.searchValue);
  }, 500)

  onScroll() {
    this.isItemsLoading = true;
    this.previousScrollTop = this.leftListRef.nativeElement.scrollTop;
    this.fetchItems();
  }

  fetchItems = _.debounce(() => {
    this.onScrollEndReached.emit(this.searchValue);
  }, 500)

  scrollToBottom() {
    if (this.leftListRef && this.leftListRef.nativeElement) {
      this.leftListRef.nativeElement.scrollTop = this.previousScrollTop;
    }
  }

  isDocument(item: any) {
    return (item as IContract).summary !== undefined && (item as IContract).createdBy !== undefined && (item as IContract).updatedAt !== undefined;
  }

  getSummary(item: any) {
    return (item as IContract).summary;
  }

  getCreatedBy(item: any) {
    return (item as IContract).createdBy;
  }

  getUpdatedAt(item: any) {
    return (item as IContract).updatedAt;
  }

  toggleSelectAll(event: CheckboxChangeEvent) {
    this.filteredData.forEach(item => {
      item.selected = event.checked;

      if (event.checked) {
        if (!this.allSelectedItems.some(selected => selected._id === item._id)) {
          this.allSelectedItems.push(item);
        }
      } else {
        this.allSelectedItems = this.allSelectedItems.filter(selected => selected._id !== item._id);
      }
    });

    this.updateSelectedItems();
  }

  updateSelectedItems() {
    const currentlySelected = this.data.filter(item => item.selected);

    currentlySelected.forEach(item => {
      if (!this.allSelectedItems.some(selected => selected._id === item._id)) {
        this.allSelectedItems.push(item);
      }
    });

    this.allSelectedItems = this.allSelectedItems.filter(selectedItem => {
      const itemInCurrentData = this.data.find(item => item._id === selectedItem._id);
      return !itemInCurrentData || itemInCurrentData.selected;
    });

    this.selectedItems = [...this.allSelectedItems];

    this.updateSelectAllState();
  }

  updateSelectAllState() {
    const filteredSelected = this.filteredData.filter(item => item.selected);
    this.selectAll = filteredSelected.length > 0 && filteredSelected.length === this.filteredData.length;
  }

  removeItem(item: any & { selected?: boolean }) {
    item.selected = false;

    this.allSelectedItems = this.allSelectedItems.filter(selectedItem => selectedItem._id !== item._id);

    const dataItem = this.data.find(dataItem => dataItem._id === item._id);
    if (dataItem) {
      dataItem.selected = false;
    }

    this.updateSelectedItems();
  }

  cancel() {
    this.searchValue = '';
    this.allSelectedItems = []
    this.visible = false
    this.visibleChange.emit(this.visible);
    this.onDialogClose.emit();
  }

  submitSelection() {
    this.onSelectionSubmit.emit(this.selectedItems);
  }
}