import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription, throttleTime } from 'rxjs';

@Directive({
  selector: '[appThrottleClick]',
  standalone: true
})
export class ThrottleClickDirective implements OnInit, OnDestroy {
  @Input() throttleTime = 1000;
  private clicks = new Subject();
  private subscription!: Subscription;
  @Output() throttledClick = new EventEmitter();

  ngOnInit() {
    this.subscription = this.clicks
      .pipe(throttleTime(this.throttleTime))
      .subscribe((e) => this.throttledClick.emit(e))
  }

  constructor() {

  }

  @HostListener('click', ['$event'])
  hostClicked(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

}
