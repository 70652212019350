<div class="phone-input-wrapper">
    <div class="phone-input-flex"
        [ngStyle]="{'border' : (formControl && formControl.dirty && formControl.invalid && !isFocused()) ?  '1px solid var(--errorColor)':'1px solid var(--gray300)'}">
        <div id="bgcdialocde">
            <span class="country-code" [class.cursor-pointer]="!readOnly" (click)="!readOnly && toggleDropDown()">
                +{{selectedPhoneNumber().countryCode}}
                <i class="pi" [ngClass]="!isDropDownOpen() ? 'pi-angle-down' : 'pi-angle-up'"></i>
            </span>
        </div>
        <input type="text" [readOnly]="readOnly" [value]="selectedPhoneNumber().contactNumber"
            (focus)="isFocused.set(true)" (blur)="isFocused.set(false)" (click)="onTouch()"
            (input)="setPhoneNumber($event)" maxlength="15">
    </div>
    @if(isDropDownOpen()){
    <div class="country-dropdown">
        <input class="country-search" type="text" placeholder="Search country" [value]="searchText()"
            (input)="searchCountry($event)">
        <ul class="dropdown-list">
            @if(searchDropDownOptions().length){
            @for(countryCode of searchDropDownOptions(); track countryCode;){
            <li class="country-code-item font-weight-500" (click)="setCountryCode(countryCode)">
                <span>{{countryCode.name}}</span>
                <span>({{countryCode.phoneCode}})</span>
            </li>
            }
            }
            @else {
            <li>No Country Found</li>
            }
        </ul>
    </div>
    }
</div>