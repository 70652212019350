import { Injectable } from '@angular/core';
import { BaseService } from '../../@core/services/base.service';
import { environment } from '../../../environments/environment.dev';
import { HttpClient } from '@angular/common/http';
import { IUser } from '../../@core/models/model';

export interface INotificationMeta {
    type: string,
    message: string,
    isRead: boolean,
}

export enum INotificationType {
    EMAIL = 'EMAIL',
    SMS = 'SMS',
    PUSH = 'PUSH',
    WEB = 'WEB',
    ALL = 'ALL'
}


export interface IMailMeta {
    subject: string,
    body: string,
    to: string,
    cc?: string,
    bcc?: string
    isDelivered: boolean
}

export const NOTIFICATION_EVENTS = {
    APPROVAL: {
        APPROVED: 'APPROVED',
        REJECTED: 'REJECTED',
        PENDING: 'PENDING',
        ADDED: 'ADDED',
    },
    SIGNATORY: {
        SIGNED: 'SIGNED',
        REJECTED: 'REJECTED',
        PENDING: 'PENDING'
    },
    SHARE: {
        SHARED: 'SHARED',
    },
    COMMENT: {
        COMMENTED: 'COMMENTED',
    },
    DOCUMENT: {
        GENERATED: 'GENERATED',
    }
}

export enum NOTIFICATION_STATUS {
    PENDING = 'PENDING',
    SENT = 'SENT',
    FAILED = 'FAILED',
    DELIVERED = 'DELIVERED'
}

export enum NOTIFICATION_PRIORITY {
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW'
}

export interface INotification extends Document {
    readonly _id: string;
    org: string,
    user: IUser | string,
    meta: INotificationMeta,
    type: INotificationType,
    module: string,
    subModule: string,
    event: {
        type: string,
        data: object,
        action: string,
        triggeredBy: IUser
    },
    status?: NOTIFICATION_STATUS;
    priority?: string;
    createdAt?: Date;
}


@Injectable({ providedIn: 'root' })
export class NotificationService extends BaseService {

    apiUrl = environment.apiUrl + '/notifications';


    constructor(
        protected override http: HttpClient
    ) {
        super(http);
    }

    getNotifications() {
        return this.get<INotification[]>(this.apiUrl + '/fetch-notifications');
    }

    getNotificationsCount() {
        return this.get<number>(this.apiUrl + '/fetch-notifications-count');
    }

    markRead(notificationId: string) {
        return this.post(this.apiUrl + '/mark-as-read/' + notificationId, {});
    }

    markAllAsRead() {
        return this.put(this.apiUrl + '/mark-all-as-read', {});
    }
}